<template>
  <UiPopup
    :model-value="modelValue"
    title="Can’t create shadow leads"
    :description="`${selectedShadowLength} from ${selectedLength} leads includes Shadow-copy. Do you want to remove such leads from selection and continue with other leads?`"
    primary-button-text="Remove and continue"
    secondary-button-text="Close"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('input')"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  selectedLength: number
  selectedShadowLength: number
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
  selectedLength: 0,
  selectedShadowLength: 0,
})
</script>

<style scoped></style>
